





















































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import ReactionBarGraph from '@/components/Presenter/ReactionBarGraph.vue';
import TVButton from '@/components/Presenter/TVButton.vue';
import NavMenuItem from '@/components/Presenter/NavMenuItem.vue';
import EmbedModal from '@/components/Presenter/EmbedModal.vue';
import ThemeModel from '@/dtos/theme';
import TabMenu from '@/components/Presenter/TabMenu.vue';
import { formatDateString } from '@/utils/tools';

export default Vue.extend({
  components: {
    ReactionBarGraph,
    TVButton,
    NavMenuItem,
    EmbedModal,
    TabMenu,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
      qrCodeURL: '',
      showEmbedModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
      sessionTheme: 'viewer/getSessionTheme',
      user: 'auth/getUser',
      reactions: 'presenter/getReactions',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    computedDataCollection(): any {
      const labels = [0, 5, 10, 20];
      const datacollection = {
        labels,
        datasets: [
          {
            label: this.$t('views.presenter.session.statsReactions'),
            backgroundColor: 'transparent',
            pointStyle: 'line',
            data: [0, 5, 10, 20],
            borderColor: '#67EDB8',
          },
          {
            label: this.$t('views.presenter.session.statsCoUsers'),
            backgroundColor: 'transparent',
            pointStyle: 'line',
            data: labels.map((i) => 0.5 * Math.sin(i / 5)),
            borderColor: '#80F5FF',
          },
        ],
      };
      return datacollection;
    },
    computedReactionsCount(): number {
      let countReactions = 0;
      if (this.currentSession && this.reactions) {
        for (const count of Object.values(this.reactions)) {
          countReactions += count as number;
        }
      }
      return countReactions;
    },
    computedReactions(): any {
      if (this.currentSession && this.reactions) {
        const { reactions } = this;
        const sessionReactions = [];

        sessionReactions.push({
          name: 'thumbs-up',
          imageName: 'reaction-thumbs-up.png',
          color: '#006733',
          value: reactions['thumbsUp'],
          fillLevel:
            (reactions['thumbsUp'] / this.computedReactionsCount) * 100,
          label: this.$t('views.thumbsUp'),
        });
        sessionReactions.push({
          name: 'heart',
          imageName: 'reaction-love.png',
          color: '#006733',
          value: reactions['heart'],
          fillLevel: (reactions['heart'] / this.computedReactionsCount) * 100,
          label: this.$t('views.loveIt'),
        });
        sessionReactions.push({
          name: 'sad',
          imageName: 'reaction-sad.png',
          color: '#006733',
          value: reactions['sad'],
          fillLevel: (reactions['sad'] / this.computedReactionsCount) * 100,
          label: this.$t('views.crying'),
        });
        sessionReactions.push({
          name: 'claps',
          imageName: 'reaction-claps.png',
          color: '#006733',
          value: reactions['claps'],
          fillLevel: (reactions['claps'] / this.computedReactionsCount) * 100,
          label: this.$t('views.clapping'),
        });

        sessionReactions.push({
          name: 'rocket',
          imageName: 'reaction-rocket.png',
          color: '#006733',
          value: reactions['rocket'],
          fillLevel: (reactions['rocket'] / this.computedReactionsCount) * 100,
          label: this.$t('views.rocket'),
        });
        sessionReactions.push({
          name: 'party',
          imageName: 'reaction-party.png',
          color: '#006733',
          value: reactions['party'],
          fillLevel: (reactions['party'] / this.computedReactionsCount) * 100,
          label: this.$t('views.party'),
        });
        sessionReactions.push({
          name: 'leaf',
          imageName: 'reaction-leaf.png',
          color: '#006733',
          value: reactions['leaf'],
          fillLevel: (reactions['leaf'] / this.computedReactionsCount) * 100,
          label: this.$t('views.leaf'),
        });
        sessionReactions.push({
          name: 'concertHouse',
          imageName: 'reaction-concertHouse.png',
          color: '#006733',
          value: reactions['concertHouse'],
          fillLevel:
            (reactions['concertHouse'] / this.computedReactionsCount) * 100,
          label: this.$t('views.concertHouse'),
        });

        sessionReactions.push({
          name: 'beer',
          imageName: 'reaction-beer.png',
          color: '#006733',
          value: reactions['beer'],
          fillLevel: (reactions['beer'] / this.computedReactionsCount) * 100,
          label: this.$t('views.beer'),
        });
        sessionReactions.push({
          name: 'digitalAudience',
          imageName: 'reaction-digitalAudience.png',
          color: '#006733',
          value: reactions['digitalAudience'],
          fillLevel:
            (reactions['digitalAudience'] / this.computedReactionsCount) * 100,
          label: this.$t('views.digitalAudience'),
        });

        return sessionReactions;
      }
      return [];
    },
    computedShareLink(): string {
      if (this.currentSession && this.currentSession.shareToken && this.user) {
        const shareURL =
          'https://konzerthaus.reaction.link/j/' +
          this.currentSession.shareToken +
          '/start';

        return shareURL;
      }
      return '';
    },
    activeMenuItems(): string[] {
      let labels = [];
      if (
        this.currentSession &&
        this.currentSession.resources &&
        this.currentSession.resources.global.navItems
      ) {
        labels = this.currentSession.resources.global.navItems;
      }
      return labels;
    },
    computedStartDate(): string {
      if (this.currentSession.startAt) {
        return formatDateString(this.currentSession.startAt.toDate());
      }
      return formatDateString(null);
    },
    computedEndDate(): string {
      if (this.currentSession.endAt && !this.currentSession.sessionUnlimited) {
        return formatDateString(this.currentSession.endAt.toDate());
      }
      return formatDateString(null);
    },
    hasSessionEnded(): boolean {
      if (!this.currentSession.sessionUnlimited) {
        return (
          !this.currentSession.endAt ||
          this.now > this.currentSession.endAt.toDate()
        );
      }
      return false;
    },
    tabItems(): any {
      return [
        {
          id: 0,
          name: 'Standard Reaktions-Set',
          isSelected:
            this.currentSession &&
            this.currentSession.resources &&
            this.currentSession.resources.reactionsPage.reactionSet ==
              'STANDARD',
          value: 'STANDARD',
        },
        {
          id: 1,
          name: 'Custom Reaktions-Set',
          isSelected:
            this.currentSession &&
            this.currentSession.resources &&
            this.currentSession.resources.reactionsPage.reactionSet == 'CUSTOM',
          value: 'CUSTOM',
        },
      ];
    },
  },
  methods: {
    closeSession() {
      if (this.currentSession.id) {
        if (
          confirm(this.$t('views.presenter.session.statsEndSession') as string)
        ) {
          this.$store.dispatch(
            'presenter/cancelSession',
            this.currentSession.id
          );
        }
      }
    },
    removeSession() {
      if (this.currentSession.id) {
        if (
          confirm(
            this.$t('views.presenter.session.statsDeleteSession') as string
          )
        ) {
          this.$store.dispatch('presenter/removeSession', {
            sessionId: this.currentSession.id,
            sessionPath: this.$route.path,
          });
        }
      }
    },
    editSession() {
      if (this.currentSession.id) {
        const { presenterSessionId } = this.$route.params;
        this.$router.push({
          name: 'Presenter Session Edit',
          params: { presenterSessionId },
        });
      }
    },
    resetReactions() {
      if (confirm('Möchtest du wirklich alle Reaktionen zurücksetzen?')) {
        this.$store.dispatch('presenter/resetReactions');
      }
    },
    openSessionLink() {
      if (this.computedShareLink) window.open(this.computedShareLink, '_blank');
    },
    onTabMenuSelect(value: string) {
      this.$store.dispatch('presenter/updateReactionSet', {
        reactionSet: value,
      });
    },
    isItemDeactivated(navItem: string): boolean {
      return (
        this.currentSession &&
        this.currentSession.deactivatedMenuItems &&
        this.currentSession.deactivatedMenuItems.includes(navItem)
      );
    },
    updateActiveItems(activeMenuItems: string[]) {
      const menuItems = [];
      if (activeMenuItems.includes('reactions')) {
        menuItems.push('reactions');
      }
      if (activeMenuItems.includes('wordclouds')) {
        menuItems.push('wordclouds');
      }
      if (activeMenuItems.includes('polls')) {
        menuItems.push('polls');
      }
      if (activeMenuItems.includes('questions')) {
        menuItems.push('questions');
      }
      menuItems.push('info');
      this.$store.dispatch('presenter/updateActiveItems', {
        labels: menuItems,
      });
    },
    async downloadQRCode(): Promise<any> {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `reaction.link QR-Code Session ${this.currentSession.name}.png`;
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open('GET', this.currentSession.qrCodeImageURL);
      xhr.send();
    },

    showCopySuccessToast(): void {
      Vue.$toast.open({
        message: this.$t('views.presenter.session.statsSuccessToast') as string,
        type: 'success',
        position: 'top-right',
      });
    },

    showCopyFailureToast(): void {
      Vue.$toast.open({
        message: this.$t('views.presenter.session.statsFailureToast') as string,
        type: 'error',
        position: 'top-right',
      });
    },

    openTVLink(): void {
      window.open(
        'https://konzerthaus.reaction.link/j/' +
          this.currentSession.shareToken +
          '/tv',
        '_blank'
      );
    },
    openEmbedModal() {
      this.showEmbedModal = true;
    },
    closeEmbedModal() {
      this.showEmbedModal = false;
    },
  },
});
